import styled, { css } from 'styled-components/native'
import { Avatar } from '../Avatar'
import { Text, ContainerWithBorder } from '@moodwork-ui'
import { DeviceSizeType } from '@ui/utils'

export const PageContainer = styled.View<{ device: DeviceSizeType }>`
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  padding-horizontal: ${(props) =>
    props.device === 'small' ? '16px;' : '96px'};
  padding-top: ${(props) => (props.device === 'small' ? '32px;' : '0px')};
 `

export const Container = styled.View`
  flex-direction: row;
`

export const ImageContainer = styled.View`
  flex: 1;
  align-items: center;
`

export const BigImage = styled.Image`
  flex: 1;
  width: 289px;
  height: auto;
`

export const Card = styled(ContainerWithBorder)<{ device: DeviceSizeType }>`
  flex: 1;
  justify-content: space-between;
  flex-basis: undefined;
  align-items: flex-start;
  max-height: 300px;
  margin-right: ${({ device }) => (device !== 'small' ? 102 : 0)}px;
`

export const EventDate = styled(Text)`
  flex: 1;
  margin-right: 8px;
  margin-bottom: 16px;
`

export const PsychologistImage = styled(Avatar)`
  flex: 1;
`

export const Name = styled.View`
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
`

export const ColContainer = styled.View`
  flex: 1;
  flex-direction: column;
  margin-bottom: 25px;
`

export const RowContainer = styled.View`
  flex: 1;
  flex-direction: row;
  flex-basis: undefined;
`

export const ButtonContainer = styled.View<{ device: string }>`
  margin-top: ${(props) => (props.device === 'small' ? 'auto' : 'undefined')};
  margin-bottom: 32px
  ${({ device }) =>
    device !== 'small' &&
    css`
    align-items: flex-start;
  `}
 
`
