import React, { forwardRef } from 'react'
import { useToggle } from '@core/lib/hooks'
import { PressableProps } from 'react-native'
import { TrackableEventProps } from '@core/lib/analytics'
import { HoverableBoxProps } from './interface'
import { Box, Content } from './index.styles'


export const HoverableBox = forwardRef(({
  text,
  handlePress,
  selected,
  large = true,
  style,
  trackLabel,
  trackProperties,
  ...props
}, ref : HoverableBoxProps &
  TrackableEventProps &
  PressableProps) => {
  const [hovered, onHoverIn, onHoverOut] = useToggle(false)

  return (
    <Box
      {...props}
      ref={ref}
      style={style}
      hover={hovered}
      large={large}
      selected={selected}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      onPress={handlePress}>
      <Content hover={hovered} selected={selected}>{text}</Content>
    </Box>
  )
})
