import { Title } from '@ui/Moodwork-UI'
import React, { useContext, useState } from 'react'
import { Container, LeftMenu, Menu, RightMenu } from './index.styles'
import { HeaderTopContext } from '@ui/Page/Page'
import ProfileLeftMenu from '@ui/Moodwork-UI/organisms/Profile/ProfileLeftMenu'
import ProfileMenu from '@ui/Moodwork-UI/organisms/Profile/ProfileRIghtMenu/ProfileMenu'
import EmailMenu from '@ui/Moodwork-UI/organisms/Profile/ProfileRIghtMenu/EmailMenu'
import PersonnalDataMenu from '@ui/Moodwork-UI/organisms/Profile/ProfileRIghtMenu/PersonnalDataMenu'
import AlertHandler from '@core/components/Alerthandler'
export default function Profile() {
  const headerIsTop = useContext(HeaderTopContext)

  const [activeIndex, setActiveIndex] = useState(0)
  const ActiveMenu = [<ProfileMenu />, <EmailMenu />, <PersonnalDataMenu />]

  return (
    <Container big={headerIsTop}>
      <Title>Mon Compte</Title>
      <Menu>
        <LeftMenu>
          <ProfileLeftMenu
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
        </LeftMenu>
        <RightMenu>{ActiveMenu[activeIndex]}</RightMenu>
      </Menu>
    </Container>
  )
}
