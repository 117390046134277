import { IS_BROWSER } from '@ui/utils'
import styled, {css} from 'styled-components/native'

const web = css`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
`

export const Box = styled.Pressable<{hover: boolean, large: boolean, selected: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.device === 'small' ? '20px' : '30px'};
  transform-origin: 50%;
  ${({hover, large, selected}) => hover ? css`
    background-color: ${({ theme }) => selected ? theme.colors.secondary : theme.colors.white};
    transform: ${large ? `scale(1.02, 1.02)` : `scale(1.04, 1.04)`};
    z-index: 99999;
    ${IS_BROWSER && web};
      shadowColor: ${({ theme }) => theme.colors.black};
      shadowOffset: {
          width: 0,
          height: 0,
      };
      shadowRadius: 15px;
      shadowOpacity: 0.1;
      elevation: 1;
  ` : selected ? css`
    background-color: ${({ theme }) => theme.colors.secondary};
  ` 
    : css`
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  `
  }
`

export const Content = styled.Text<{hover: boolean, selected: boolean}>`
  font-size: 16px;
  color: ${({ theme, hover, selected }) => selected ? theme.colors.white : hover ? theme.colors.secondary : theme.colors.black }
`
