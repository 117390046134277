import { useMutation, useQuery, UseQueryResult } from 'react-query'
import {
  AUTH_CLIENT_ID,
  LOCALE_KEY,
  TIMEZONE_KEY,
  MARKETING_ID_TOKEN_KEY,
} from '../constants'
import { Session } from '../store'
import { useHttp } from './http'
import * as WebBrowser from 'expo-web-browser'
import * as Sentry from 'sentry-expo'
import { useTranslation } from 'react-i18next'
import { Viewer } from '@core/types/viewer'
import { AxiosError } from 'axios'
import { storeItem } from '@core/lib/storage'
import moment from 'moment-timezone'

interface LoginPayload {
  username: string
  password: string
  captchaToken?: string
}

/**
 * Get the viewer object
 */
export function useUserInfo(): UseQueryResult<Viewer, AxiosError> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getViewer = async () => {
    const userInfo = await http.get(`/oauth/userinfo`)
    return userInfo?.data
  }

  return useQuery(['viewer', i18n.language], getViewer, {
    // workaround for first call returning 401 issue
    retry: 1,
    onSuccess: async ({ marketing_id, selected_language }) => {
      await storeItem(MARKETING_ID_TOKEN_KEY, marketing_id)
      await storeItem(LOCALE_KEY, selected_language)
      await storeItem(TIMEZONE_KEY, moment.tz.guess())
    },
  })
}

export function useConfirmEmail(token: String) {
  const http = useHttp()
  const session = Session.useState()
  const confirmEmail = () => {
    return http.get('/api/v3/users/confirmation?confirmation_token=' + token)
  }
  return useQuery(['confirmEmail'], confirmEmail, {
    retry: 0,
    onSuccess: ({ data }) => {
      session.dispatch(
        Session.setTokens({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          tokenExpiration: data.created_at + data.expires_in,
        })
      )
    },
  })
}

export function useSendDeviceToken() {
  const http = useHttp()
  const sendDeviceToken = async (token: string) => {
    const userInfo = await http.put(
      `api/v1/user`,
      { user: { device_token: token } },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
    return userInfo?.data
  }
  return useMutation(['deviceToken'], sendDeviceToken)
}

/**
 * TODO: on the backend, this should actually be a GET request.
 */
export function useSSOVerification() {
  const http = useHttp()
  const checkEmailForSSO = (email: string) => {
    return http.post(
      `/api/v1/sso_redirect_url`,
      { email: email },
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
  return useMutation(['sso:check-email'], checkEmailForSSO, { retry: 1 })
}

export function useSignIn(locale: string = 'fr') {
  const http = useHttp()
  const session = Session.useState()
  const signIn = ({ username, password, captchaToken }: LoginPayload) => {
    const [user, pass] = [username, password].map(encodeURIComponent)
    const url = `grant_type=password&g-recaptcha-response-data=${captchaToken}&username=${user}&password=${pass}&client_id=${AUTH_CLIENT_ID}&scope=openid profile`
    return http.post('/oauth/token?locale=' + locale, url)
  }
  return useMutation(['sign-in'], signIn, {
    retry: 0,
    onSuccess: ({ data }) => {
      session.dispatch(
        Session.setTokens({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          tokenExpiration: data.created_at + data.expires_in,
        })
      )
    },
  })
}

export function useSignUp(locale: string = 'fr') {
  const session = Session.useState()
  const http = useHttp()
  const signUp = ({
    firstName,
    lastName,
    password,
    email,
    personnalEmail,
    companyCode,
    subToNewsletter,
    captchaToken,
  }: {
    firstName: string
    password: string
    lastName: string
    email: string
    personnalEmail: string
    companyCode: string
    subToNewsletter: boolean
    captchaToken?: string
  }) => {
    return http.post(
      '/api/v3/users/registration?locale=' + locale,
      {
        user: {
          first_name: firstName,
          last_name: lastName === '' ? undefined : lastName,
          password: password === '' ? undefined : password,
          professional_email: email,
          personal_email: personnalEmail,
          company_code_content: companyCode,
          mail_communication: subToNewsletter,
          source: 'moodwork',
          'g-recaptcha-response-data': captchaToken,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    )
  }
  return useMutation(['sign-up'], signUp, {
    retry: 0,
    onSuccess: ({ data }) => {
      session.dispatch(
        Session.setTokens({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          tokenExpiration: data.created_at + data.expires_in,
        })
      )
    },
  })
}

export function useUpdateUser() {
  const http = useHttp()
  const updateUser = ({
    personalEmail,
    subToNewsletter,
    privacyConsent,
    newsletter,
  }: {
    personalEmail: string
    subToNewsletter: boolean
    privacyConsent: boolean
    newsletter: boolean
  }) => {
    return http.put(
      `/api/v3/user`,
      {
        user: {
          personal_email: personalEmail,
          mail_communication: subToNewsletter,
          privacy_consent: privacyConsent,
          newsletter: newsletter,
        },
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
  return useMutation(['updateUser'], updateUser, { retry: 1 })
}

export function usePassword(token: string = '', locale: string = 'fr') {
  const session = Session.useState()
  const http = useHttp()
  const setPassword = ({
    password,
    currentPassword,
  }: {
    password: string
    currentPassword?: string
  }) => {
    return http.put(
      '/api/v1/user/password?locale=' + locale,
      {
        user: {
          password: password,
          token: token,
          current_password: currentPassword,
        },
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
  return useMutation(['setPassword'], setPassword, {
    retry: 0,
    onSuccess: ({ data }) => {
      session.dispatch(
        Session.setTokens({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          tokenExpiration: data.created_at + data.expires_in,
        })
      )
    },
  })
}

export function useCheckCompanyCode(locale: String) {
  const http = useHttp()
  const checkCompanyCode = (code) => {
    return http.get('/api/v1/company_code/' + code + '?locale=' + locale)
  }
  return useMutation(['checkCompanyCode'], checkCompanyCode, {
    retry: 0,
  })
}

export function useDeleteAccount() {
  const http = useHttp()
  const deleteAccount = ({ password }: { password: string }) => {
    return http.post(
      '/api/v1/user/delete',
      {
        user: {
          password: password,
        },
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
  return useMutation(['deleteAccount'], deleteAccount, {
    retry: 0,
  })
}

export function useDownloadData() {
  const http = useHttp()
  const downloadData = ({ password }: { password: string }) => {
    return http.post(
      '/api/v1/user/download_data',
      {
        user: {
          password: password,
        },
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
  return useMutation(['downloadData'], downloadData, {
    retry: 0,
  })
}

WebBrowser.maybeCompleteAuthSession()

export async function ssoAuthenticate(url: string) {
  try {
    WebBrowser.dismissBrowser()
  } catch (e) {
    Sentry.Browser.captureException(e)
  }
  const result = await WebBrowser.openBrowserAsync(url)
  return result
}
