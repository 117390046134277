import React from 'react'
import { Container, Content, CloseButton } from './index.styles'
import { FeedbackHeaderProps } from './interface'
import {
  FontIcon,
  HTMLView,
} from '@moodwork-ui'
import { useTheme } from '@ui/utils'

export const FeedbackHeader = ({
  color,
  content,
  setVisible
}: FeedbackHeaderProps) => {
  const theme = useTheme()
 
  return (
    <Container color={color}>
      <CloseButton onPress={() => setVisible(false)}>
        <FontIcon name='cancel' color={theme.colors.white} size={16} />
      </CloseButton>
      <Content>
        <HTMLView
          source={content}
          textColor={theme.colors.white}
          big
        />
      </Content>
    </Container>
  )
}
