import React, { createRef, useContext, useEffect, useState } from 'react'
import { HoverableBox } from '../HoverableBox'
import { OnScrollContext } from '@ui/Page/Page'
import { ResourceArrayBoxProps } from './interface'

export const ResourceArrayBox = ({
  large,
  text,
  html,
  id,
  style,
  setFeedback,
  selectedResponse,
}: ResourceArrayBoxProps) => {
  const { onScroll } = useContext(OnScrollContext)
  const [positionY, setPositionY] = useState(0)
  const boxRef = createRef()

  const handlePress = () => {
    if (id === selectedResponse) {
      setFeedback(null, null, null, 0)
    } else {
      setFeedback(html, 'neutral', positionY, id)
    }
  }

  const handlePosition = () => {
    boxRef.current &&
      boxRef.current.measure((width, height, px, py, fx, fy) => {
        setPositionY(fy - 49 + (onScroll?.nativeEvent?.contentOffset?.y ?? 0))
      })
  }

  useEffect(() => {
    handlePosition()
  }, [onScroll, boxRef, selectedResponse])

  return (
    <HoverableBox
      ref={boxRef}
      selected={selectedResponse === id}
      large={large}
      text={text}
      style={style}
      handlePress={handlePress}
    />
  )
}
