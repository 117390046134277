import * as React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
  HOST,
  SCREEN_AUTH_BROWSER,
  SCREEN_COMMING_SOON,
  SCREEN_CONFERENCES,
  SCREEN_CONFIDENTIALITY_INFO,
  SCREEN_CONTENTS,
  SCREEN_HOME,
  SCREEN_HOME_INTERCOM,
  SCREEN_PSYCHOLOGIST_APPOINTMENT_CONFIRMATION,
  SCREEN_PSYCHOLOGIST_APPOINTMENT_INFO,
  SCREEN_PSYCHOLOGIST_APPOINTMENT_TYPE,
  SCREEN_PSYCHOLOGIST_CALENDAR,
  SCREEN_PSYCHOLOGISTS,
  SCREEN_RESOURCE,
  SCREEN_SIGNIN,
  SCREEN_SIGNUP_INTRO,
  SCREEN_SIGNUP_EMAIL,
  SCREEN_SIGNUP_SSO,
  SCREEN_TEST_RESULT,
  SCREEN_WELCOME,
  SCREEN_COMPANY,
  SCREEN_SEARCH,
  SCREEN_COLLECTION,
  SCREEN_LIBRARY,
  SCREEN_PROFILING,
  SCREEN_REGISTRATION,
  SCREEN_WELLBEING_TRACKING,
  SCREEN_PORTALS,
  SCREEN_ASSESSMENT,
  SCREEN_PASSWORD,
  MODAL_WELCOME,
  MODAL_ASSESSMENT,
  MODAL_APPOINTMENT_TUTORIAL,
  MODAL_SHARE_RESOURCE,
  SCREEN_PROFILE,
  SCREEN_PROGRAM,
  SCREEN_SSO,
  SCREEN_CONTACT,
  SCREEN_FAQ,
  MODAL_CANCEL_EVENT,
  SCREEN_PRIVATE_WEBINARS,
  SCREEN_PUBLIC_WEBINARS,
  SCREEN_SIGNUP_EMAIL_CONFIRMATION,
  MODAL_CONFIRMATION,
  MARKETING_ID_TOKEN_KEY,
  MODAL_DELETE_ACCOUNT,
  MODAL_DOWNLOAD_DATA,
} from '../constants'
import { RootStackParamList } from '../lib/types'
import Psychologists from '../screens/Psychologists'
import PsychologistCalendar from '../screens/PsychologistCalendar'
import {
  NavigationContainer,
  useNavigationContainerRef,
  useRoute,
} from '@react-navigation/native'
import Conferences from '../screens/Conferences'
import Contents from '../screens/Contents'
import { IS_NATIVE, Page } from '../../ui'
import CommingSoon from '../screens/CommingSoon'
import Welcome from '../screens/Welcome'
import SignIn from '../screens/SignIn'
import SignUpIntro from '../screens/SignUpIntro/'
import ConfidentialityInfo from '../screens/ConfidentialityInfo'
import AuthBrowser from './AuthBrowser'
import PsychologistAppointmentType from '../screens/PsychologistAppointmentType'
import PsychologistAppointmentConfirmation from '../screens/PsychologistAppointmentConfirmation'
import PsychologistAppointmentInfo from '../screens/PsychologistAppointmentInfo'
import { trackScreen } from '../lib/analytics'
import TestResult from '../screens/TestResult'
import { DISPLAY_IN_PROGRESS_SCREENS } from '../lib/navigation'
import Resource from '../screens/Resource'
import Dashboard from '../screens/Dashboard/index'
import Company from '../screens/Company'
import Search from '../screens/Search/index'
import Collection from '@core/screens/Collection'
import { useFlipper } from '@react-navigation/devtools'
import Profiling from '@core/screens/Profiling'
import Registration from '@core/screens/Registration'
import { IS_BROWSER, useTheme } from '@ui/utils'
import WellbeingTracking from '@core/screens/WellbeingTracking'
import Portals from '@core/screens/Portals'
import Assessments from '@core/screens/Assessments'
import SignUp from '@core/screens/SignUp'
import PasswordForm from '@ui/Moodwork-UI/organisms/SignUp/PasswordForm'
import WelcomeModal from '@ui/Moodwork-UI/organisms/Dashboard/WelcomeModal'
import { View } from 'moti'
import AssessmentModal from '@ui/Moodwork-UI/molecules/AssessmentModal/AssessmentModal'
import AppointmentTutorialModal from '@ui/AppointmentTutorialModal'
import { ShareResourceModal } from '@ui/Moodwork-UI/molecules/ShareResourceModal'
import { CancelEventModal } from '@ui/Moodwork-UI/molecules/CancelEventModal'
import Profile from '@core/screens/Profile'
import FAQ from '@core/screens/FAQ'
import Webinars from '@core/screens/Webinars'
import Program from '@core/screens/Program'
import SSO from '@ui/Moodwork-UI/organisms/SignIn/SSO'
import Contact from '@core/screens/Contact'
import EmailConfirmation from '@core/screens/EmailConfirmation'
import ConfirmationModal from '@ui/Moodwork-UI/organisms/Dashboard/ConfirmationModal'
import { setAnalyticMarketingId } from '@core/lib/analytics'
import { getCookie } from '@core/lib/cookies'
import DeleteModal from '@core/screens/Profile/DeleteModal'
import DownloadDataModal from '@core/screens/Profile/DownloadDataModal'

/**
 * Wrap all routes inside a Page component
 * This component will also be used to update
 * the header height
 * when scrolling.
 * TODO: remove, and handle pages in components maybe
 * Replace with somthing like `const { expandHeader } = useHeader()`
 * So maybe add a header provider inside the proviers list
 */
export const route =
  (
    C: (props: any) => JSX.Element,
    allowGuest?: boolean,
    hideHeader?: boolean,
    hideFooter?: boolean
  ) =>
  (props: any) => {
    const { name, params } = useRoute()
    return (
      <Page
        key={`${name}-${params?.id || params?.q || 0}`}
        allowGuest={allowGuest}
        hideHeader={hideHeader}
        hideFooter={hideFooter}>
        <C {...props} />
      </Page>
    )
  }

/**
 * TODO:
 *  - implement URL for the web : https://reactnavigation.org/docs/configuring-links/
 *  - https://reactnavigation.org/docs/auth-flow
 */
const Stack = createNativeStackNavigator<RootStackParamList>()

export default function Router() {
  const initialScreen = IS_NATIVE ? SCREEN_SIGNUP_INTRO : undefined

  // use :id for unique element identifier to force page rendering
  const linking = {
    prefixes: [`${HOST}/`, 'moodwork://'],
    config: {
      screens: {
        [SCREEN_HOME]: '/:locale?/dashboard',
        [MODAL_WELCOME]: '/:locale?/dashboard/WelcomeModal',
        [SCREEN_HOME_INTERCOM]: '/messages',
        [SCREEN_COMPANY]: '/company',
        [SCREEN_SIGNIN]: '/:locale?/users/sign_in',
        [SCREEN_SSO]: '/:locale?/sso_profile',
        [SCREEN_SIGNUP_EMAIL]: '/:locale?/users/sign_up',
        [SCREEN_SIGNUP_EMAIL_CONFIRMATION]: '/:locale?/confirmation',
        [MODAL_CONFIRMATION]: '/:locale?/confirmation-modal',
        [SCREEN_SIGNUP_INTRO]: '/:locale?/intro',
        [SCREEN_AUTH_BROWSER]: '/authorize',
        [SCREEN_PSYCHOLOGISTS]: '/:locale?/events/new',
        [MODAL_APPOINTMENT_TUTORIAL]:
          '/:locale?/events/new/AppointmentTutorialModal',
        [SCREEN_PSYCHOLOGIST_CALENDAR]: '/:locale?/events/calendar',
        [SCREEN_PSYCHOLOGIST_APPOINTMENT_TYPE]:
          '/:locale?/events/appointment-type',
        [SCREEN_PSYCHOLOGIST_APPOINTMENT_INFO]:
          '/:locale?/events/appointment-info',
        [SCREEN_PSYCHOLOGIST_APPOINTMENT_CONFIRMATION]:
          '/:locale?/events/appointment-confirmation',
        [SCREEN_TEST_RESULT]: '/:locale?/user_test/:id',
        [SCREEN_WELLBEING_TRACKING]: '/:locale?/user/wellbeing_tracker',
        [SCREEN_LIBRARY]: '/:locale?/diaries',
        [SCREEN_RESOURCE]: '/:locale?/diaries/:id',
        [SCREEN_SEARCH]: '/:locale?/search',
        [SCREEN_COLLECTION]: '/:locale?/diary_groups/:id',
        [SCREEN_PORTALS]: '/:locale?/portals/:id',
        [SCREEN_PROFILING]: '/:locale?/profiling',
        [SCREEN_REGISTRATION]: '/:locale?/registration',
        [SCREEN_ASSESSMENT]: '/:locale?/checkups/:key',
        [MODAL_ASSESSMENT]:
          '/:locale?/checkups/wellbeing_assessment/AssessmentModal',
        [SCREEN_PASSWORD]: '/:locale?/password',
        [SCREEN_PROFILE]: ':locale?/user/profile/edit',
        [MODAL_DELETE_ACCOUNT]: ':locale?/user/profile/deleteModal',
        [MODAL_DOWNLOAD_DATA]: ':locale?/user/profile/downloadModal',
        [SCREEN_PUBLIC_WEBINARS]: ':locale?/webinars/:id',
        [SCREEN_PRIVATE_WEBINARS]: ':locale?/webinars/:id/private',
        [SCREEN_PROGRAM]: ':locale?/program',
        [SCREEN_CONTACT]: ':locale?/contact',
        [SCREEN_FAQ]: ':locale?/faq',
      },
    },
  }

  if (IS_BROWSER) {
    setAnalyticMarketingId(getCookie(MARKETING_ID_TOKEN_KEY))
  }
  const routeNameRef = React.useRef()
  const routePathRef = React.useRef()
  const theme = useTheme()
  const navigationRef = useNavigationContainerRef()

  useFlipper(navigationRef)

  React.useEffect(() => {
    if (IS_BROWSER) {
      setAnalyticMarketingId(getCookie(MARKETING_ID_TOKEN_KEY)).then(() => {
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name
        trackScreen(currentRouteName, currentRouteName)
      })
    }
  }, [])

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onReady={() => {
        routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
        routePathRef.current =
          window?.location?.href ||
          navigationRef.current?.getCurrentRoute()?.name
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const previousRoutePath = routePathRef.current
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name
        const currentRoutePath =
          window?.location?.href ||
          navigationRef.current?.getCurrentRoute()?.name
        if (
          !currentRouteName?.includes('Modal') &&
          !previousRouteName?.includes('Modal')
        ) {
          if (
            previousRouteName !== currentRouteName ||
            previousRoutePath !== currentRoutePath
          ) {
            // The line below uses the expo-firebase-analytics tracker
            // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
            // Change this line to use another Mobile analytics SDK
            if (previousRouteName && previousRoutePath) {
              if (window && window.document) {
                delete window.document.referrer
                window.document.__defineGetter__('referrer', function () {
                  return previousRoutePath
                })
              }
            }
            await trackScreen(currentRouteName, currentRouteName)
          }
        }
        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName
        routePathRef.current =
          window?.location?.href ||
          navigationRef.current?.getCurrentRoute()?.name
      }}>
      <>
        <Stack.Navigator
          initialRouteName={initialScreen}
          screenOptions={{
            animationEnabled: IS_NATIVE,
            cardStyle: {
              backgroundColor: 'white',
            },
            title: 'Moodwork',
            headerShown: false,
          }}>
          <Stack.Screen name={SCREEN_HOME} component={route(Dashboard)} />
          <Stack.Screen
            name={SCREEN_HOME_INTERCOM}
            component={route(Dashboard)}
          />
          <Stack.Screen name={SCREEN_COMPANY} component={route(Company)} />
          <Stack.Screen
            name={SCREEN_TEST_RESULT}
            component={route(TestResult)}
          />
          <Stack.Screen
            name={SCREEN_ASSESSMENT}
            component={route(Assessments, false, true, true)}
          />
          <Stack.Screen
            name={SCREEN_WELLBEING_TRACKING}
            component={route(WellbeingTracking)}
          />
          <Stack.Group>
            <Stack.Screen
              name={SCREEN_PSYCHOLOGISTS}
              component={route(Psychologists)}
            />
            <Stack.Screen
              name={SCREEN_PSYCHOLOGIST_CALENDAR}
              component={route(PsychologistCalendar)}
            />
            <Stack.Screen
              name={SCREEN_PSYCHOLOGIST_APPOINTMENT_TYPE}
              component={route(PsychologistAppointmentType)}
            />
            <Stack.Screen
              name={SCREEN_PSYCHOLOGIST_APPOINTMENT_INFO}
              component={route(PsychologistAppointmentInfo)}
            />
            <Stack.Screen
              name={SCREEN_PSYCHOLOGIST_APPOINTMENT_CONFIRMATION}
              component={route(PsychologistAppointmentConfirmation)}
            />
          </Stack.Group>
          <Stack.Group>
            <Stack.Screen name={SCREEN_SEARCH} component={route(Search)} />
            <Stack.Screen
              name={SCREEN_COLLECTION}
              component={route(Collection)}
            />
            <Stack.Screen name={SCREEN_PORTALS} component={route(Portals)} />
            <Stack.Screen name={SCREEN_LIBRARY} component={route(Search)} />
            <Stack.Screen
              name={SCREEN_RESOURCE}
              component={route(Resource, false, theme.device === 'small')}
            />
          </Stack.Group>
          <Stack.Group>
            <Stack.Screen name={SCREEN_SIGNUP_INTRO} component={SignUpIntro} />
            <Stack.Screen name={SCREEN_PASSWORD} component={PasswordForm} />
            <Stack.Screen
              name={SCREEN_SIGNUP_EMAIL}
              component={route(SignUp, true, true, true)}
            />
            <Stack.Screen
              name={SCREEN_SIGNUP_EMAIL_CONFIRMATION}
              component={route(EmailConfirmation, true, true, true)}
            />
          </Stack.Group>
          <Stack.Screen
            name={SCREEN_SIGNIN}
            component={route(SignIn, true, true, true)}
          />
          <Stack.Screen
            name={SCREEN_SSO}
            component={route(SSO, true, true, true)}
          />
          <Stack.Screen
            name={SCREEN_PROFILING}
            component={route(Profiling, false, true, true)}
          />
          <Stack.Screen
            name={SCREEN_REGISTRATION}
            component={route(Registration, false, true, true)}
          />
          <Stack.Screen
            name={SCREEN_CONTACT}
            component={route(Contact, false, false, false)}
          />
          <Stack.Screen
            name={SCREEN_FAQ}
            component={route(FAQ, false, false, false)}
          />
          <Stack.Screen
            name={SCREEN_PUBLIC_WEBINARS}
            component={route(Webinars, true, true, true)}
          />
          <Stack.Screen
            name={SCREEN_PRIVATE_WEBINARS}
            component={route(Webinars, false, false, false)}
          />
          <Stack.Screen
            name={SCREEN_PROFILE}
            component={route(Profile, false, false, false)}
          />
          {IS_NATIVE && (
            <Stack.Group>
              <Stack.Screen
                name={SCREEN_PROGRAM}
                component={route(Program, false, false, false)}
              />
            </Stack.Group>
          )}

          {/* Modals starts here*/}
          <Stack.Group
            screenOptions={{
              cardOverlayEnabled: true,
              presentation: 'transparentModal',
              cardOverlay: () => {
                // needed for web
                return <View />
              },
              cardStyle: {
                backgroundColor: 'transparent',
                // needed for web
              },
            }}>
            <Stack.Screen name={MODAL_WELCOME} component={WelcomeModal} />
            <Stack.Screen
              name={MODAL_CONFIRMATION}
              component={ConfirmationModal}
            />
            <Stack.Screen name={MODAL_ASSESSMENT} component={AssessmentModal} />
            <Stack.Screen
              name={MODAL_APPOINTMENT_TUTORIAL}
              component={AppointmentTutorialModal}
            />
            <Stack.Screen
              name={MODAL_SHARE_RESOURCE}
              component={ShareResourceModal}
            />
            <Stack.Screen
              name={MODAL_CANCEL_EVENT}
              component={CancelEventModal}
            />
            <Stack.Screen name={MODAL_DELETE_ACCOUNT} component={DeleteModal} />
            <Stack.Screen
              name={MODAL_DOWNLOAD_DATA}
              component={DownloadDataModal}
            />
          </Stack.Group>
          {/* Modals ends here*/}

          {DISPLAY_IN_PROGRESS_SCREENS && (
            <>
              <Stack.Screen
                name={SCREEN_CONTENTS}
                component={route(Contents)}
              />
              <Stack.Screen name={SCREEN_WELCOME} component={route(Welcome)} />
              <Stack.Screen
                name={SCREEN_COMMING_SOON}
                component={route(CommingSoon)}
              />
              <Stack.Screen
                name={SCREEN_SIGNUP_SSO}
                component={route(CommingSoon)}
              />
              <Stack.Screen
                name={SCREEN_CONFIDENTIALITY_INFO}
                component={route(ConfidentialityInfo)}
              />
              <Stack.Screen
                name={SCREEN_AUTH_BROWSER}
                component={route(AuthBrowser)}
              />
              <Stack.Screen
                name={SCREEN_CONFERENCES}
                component={route(Conferences)}
              />
            </>
          )}
        </Stack.Navigator>
      </>
    </NavigationContainer>
  )
}
