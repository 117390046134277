import { AlertContext } from '@core/components/Alerthandler'
import { SCREEN_SIGNIN } from '@core/constants'
import { usePassword, useUpdateUser, useUserInfo } from '@core/services/viewer'
import { useNavigation } from '@react-navigation/native'
import { Line, Title } from '@ui/Moodwork-UI/atoms'
import { Button } from '@ui/Moodwork-UI/molecules'
import { TextInput } from '@ui/TextInput'
import React, { useContext, useEffect, useState } from 'react'

export default function ProfileMenu() {
  const { data } = useUserInfo()
  const { setMessage, setType, setOffsetY } = useContext(AlertContext)
  const {
    mutateAsync: mutateUser,
    data: updateUserData,
    error: updateUserError,
  } = useUpdateUser()
  const {
    mutateAsync: mutatePassword,
    data: passwordData,
    error,
  } = usePassword()
  const [personalEmail, setPersonalEmail] = useState(data?.personal_email)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const navigation = useNavigation()

  useEffect(() => {
    if (passwordData) {
      setOffsetY(500)
      setType('success')
      setMessage(
        'Votre mot de passe a été mis à jour, vous allez être déconnecter'
      )
      setTimeout(() => navigation.push(SCREEN_SIGNIN), 3000)
    }
    if (error) {
      setOffsetY(500)
      setType('warning')
      setMessage(error.response.data.message)
    }
  }, [passwordData, error])

  useEffect(() => {
    if (updateUserData) {
      setOffsetY(200)
      setType('success')
      setMessage(
        'Vous avez reçu un email permettant de valider votre adresse mail privée.'
      )
    }
    if (updateUserError) {
      setOffsetY(200)
      setType('warning')
      setMessage(updateUserError.response.data.message)
    }
  }, [updateUserData, updateUserError])

  const updateEmail = () => {
    if (!personalEmail) {
      return
    }
    if (
      personalEmail?.match(/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      mutateUser({ personalEmail: personalEmail })
    } else {
      setOffsetY(200)
      setMessage('Email non valide')
      setType('warning')
    }
  }

  const updatePassword = () => {
    if (!oldPassword && !newPassword && !passwordConfirmation) {
      return
    }
    if (!oldPassword) {
      setOffsetY(500)
      setMessage('Veuillez renseigner votre mot de passe actuel')
      setType('warning')
      return
    }
    if (newPassword === '') {
      return
    }
    if (newPassword != passwordConfirmation) {
      setOffsetY(500)
      setMessage('Mot de passe et confirmation non identique')
      setType('warning')
      return
    }
    mutatePassword({ password: newPassword, currentPassword: oldPassword })
  }

  return (
    <>
      <Title
        style={{ paddingHorizontal: 32, paddingTop: 8, paddingBottom: 24 }}
        weight='regular'
        marginSize='0'>
        Profil
      </Title>
      <Line height={2} color='separatorGrey' />
      <TextInput
        style={{ marginLeft: 32, marginTop: 16, width: '50%' }}
        type='text'
        label='Votre prénom'
        value={data?.name}
        disabled
      />
      <TextInput
        style={{ marginLeft: 32, marginTop: 8, width: '50%' }}
        type='text'
        label='Votre email professionnel'
        value={data?.professional_email}
        disabled
      />
      <TextInput
        style={{
          marginLeft: 32,
          marginTop: 8,
          marginBottom: 16,
          width: '50%',
        }}
        type='text'
        value={personalEmail}
        onChangeText={setPersonalEmail}
        label='Votre email privée'
      />
      <Button
        onPress={updateEmail}
        label='Modifier votre email'
        style={{ width: '40%', marginLeft: 32, marginBottom: 32 }}
      />
      <Line height={2} color='separatorGrey' />
      <Title
        style={{ paddingHorizontal: 32, paddingVertical: 16 }}
        weight='regular'
        marginSize='0'>
        Modifier votre mot de passe
      </Title>
      <TextInput
        style={{ marginLeft: 32, marginTop: 16, width: '50%' }}
        type='password'
        passwordIconTopPosition={38}
        label='Mot de passe actuel'
        onChangeText={setOldPassword}
      />
      <TextInput
        style={{ marginLeft: 32, marginTop: 8, width: '50%' }}
        type='password'
        passwordIconTopPosition={38}
        label='Nouveau mot de passe'
        onChangeText={setNewPassword}
      />
      <TextInput
        style={{
          marginLeft: 32,
          marginTop: 8,
          marginBottom: 16,
          width: '50%',
        }}
        type='password'
        passwordIconTopPosition={38}
        label='Confirmer votre nouveau mot de passe'
        onChangeText={setPasswordConfirmation}
      />
      <Button
        compact
        label='Modifier votre mot de passe'
        style={{ width: '40%', marginLeft: 32 }}
        onPress={updatePassword}
      />
    </>
  )
}
