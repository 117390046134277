import styled, { css } from 'styled-components/native'
import { Swiper } from '../Swiper'
import { IS_BROWSER } from '../utils/device'
import { themeColor } from '../utils/styled'
import { ListItemContainerProps } from './AppointmentCalendar.types'
import { Paragraph } from '../Moodwork-UI'
import { HTMLView } from '../Moodwork-UI/atoms/HTMLView'
import { StyleSheet } from 'react-native'

export const Container = styled(Swiper)`
  height: 100%;
`

export const Wrapper = styled.View`
  overflow: hidden;
  flex: 1;
`

export const ListItemContainer = styled.View<ListItemContainerProps>`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  position: relative;
  height: 100%;
`

export const EmptyItemWrapper = styled.View<{ inactive: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  ${({ inactive, theme }) =>
    inactive &&
    css`
    top: ${theme.spacings.XXL}px
  `}
`

export const EmptyText = styled(Paragraph)<{ clickable?: boolean }>`
    color: ${(props) =>
      props.clickable
        ? props.theme.colors.secondary
        : props.theme.colors.black};
    text-decoration: ${(props) => (props.clickable ? 'underline' : 'none')};
    text-decoration-color: ${(props) =>
      props.clickable ? props.theme.colors.secondary : 'undefined'};
    margin-right: ${(props) => (props.clickable ? '3px' : 'undefined')};
`

const web =
  IS_BROWSER &&
  css<{ clickable?: boolean }>`
    cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  `

export const EmptyPressable = styled.Pressable<{ clickable?: boolean }>`
  ${web};
  display: flex;
  flex-direction: row;
`

export const EmptyContainer = styled.View<{ clickable?: boolean }>`
  background-color: ${themeColor('secondaryLighter')};
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-direction: row;
  ${web};
`

export const EmptyContent = styled.Text`
  color: ${themeColor('secondary')};
  font-family: Asap_700Bold;
  font-size: 16px;
  margin-right: 8px;
`

export const IconContainer = styled.View`
  width: 32px;
`

export const InactiveMessage = styled(HTMLView)`
  display: flex;
  flex-direction: row;
`

const htmlMargin = { marginTop: 16 }
export const styles = StyleSheet.create({
  p: htmlMargin,
  b: htmlMargin,
  div: htmlMargin,
  i: htmlMargin,
  strong: htmlMargin,
})
