import AssessmentQuestion from '@ui/Moodwork-UI/molecules/AssessmentQuestion'
import BurnoutSlider from '@ui/Moodwork-UI/molecules/BurnoutSlider'
import WellbeingSlider from '@ui/Moodwork-UI/molecules/WellbeingSlider'
import styled from 'styled-components/native'

export const Container = styled.View<{ isDisable: boolean }>`
flex-direction: row;
align-items: center;
justify-content: ${({ theme }) =>
  theme.device === 'large' ? 'space-evenly' : 'space-between'};
opacity: ${({ isDisable }) => (isDisable ? 0.4 : 1)};
`

export const Question = styled(AssessmentQuestion)<{
  type: 'wellbeing' | 'burnout'
}>`
flex: 1;
max-width: ${({ theme, type }) =>
  theme.device === 'large'
    ? 'undefined'
    : type === 'wellbeing'
    ? '48%'
    : 'undefined'};
`

export const LeftQuestion = styled(Question)<{
  value: number
  type: 'wellbeing' | 'burnout'
}>`
opacity: ${({ value, type }) => (type === 'burnout' ? 1 : value > 0 ? 0.4 : 1)};
`

export const Slider = styled(WellbeingSlider)`
  flex: 1;
  flex-basis: undefined;
  cursor: pointer;
  margin-top: ${({ theme }) => (theme.device === 'large' ? 0 : 32)}px;
  margin-horizontal: ${({ theme }) => (theme.device === 'large' ? 32 : 0)}px;
`

export const BurnSlider = styled(BurnoutSlider)`
  flex: 1;
  flex-basis: undefined;
  cursor: pointer;
  width: ${({ theme }) => (theme.device === 'large' ? undefined : 90)}%;
  margin-top: ${({ theme }) => (theme.device === 'large' ? 0 : 32)}px;
  margin-horizontal: ${({ theme }) => (theme.device === 'large' ? 32 : 0)}px;
`

export const RightQuestion = styled(Question)<{ value: number }>`
opacity: ${({ value }) => (value < 0 ? 0.4 : 1)};
`
