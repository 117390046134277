import styled, { css } from 'styled-components/native'
import { IS_BROWSER, IS_NATIVE } from '../../../utils/device'
import { Colors } from '@ui/UIProvider/UIProvider.types'

export const Container = styled.View<{ backgroundColor?: keyof Colors }>`
  backgroundColor: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : 'transparent'};
  ${IS_BROWSER && css`flex: 1`};
  width: 100%;
  height: 100%;
`

export const ModalWrapper = styled.Pressable<{ hasBackgroundOpacity: boolean }>`
  flex: 1;
  align-items: center;
  justify-content: center;
  ${({ hasBackgroundOpacity }) =>
    hasBackgroundOpacity && css`background-color: rgba(0, 0, 0, 0.5)`}
  ${IS_BROWSER && css`cursor: default;`}
`

export const ModalContainer = styled.Pressable<{
  big: boolean
  small: boolean
}>`
  background-color: white;
  width: 75%;
  height: 75%;
  padding: 10px;
  ${({ small }) =>
    small &&
    css`
      width: 50%;
      height: 50%;`}
  ${({ big }) =>
    big &&
    css`
      width: 85%;
      height: 85%;`}
  ${({ theme }) =>
    theme.device === 'small' &&
    css`
      width: 100%;
      height: 100%;
    `}
  ${({ theme }) =>
    theme.device === 'medium' &&
    css`
      width: 90%;
      height: 90%;
    `}
   ${
     IS_BROWSER &&
     css`
    cursor: default;
  `
   }
`

export const ModalClose = styled.Text`
  position: absolute;
  font-weight: bold;
  top: 16px;
  right: 32px;
  z-index: 1;
`
export const IconContainer = styled.View`
  width: 32px;
`
