import { IS_BROWSER } from '@ui/utils'
import styled from 'styled-components/native'
import { DashboardCardOptions } from './index.types'

export const DashboardCardContainer = styled.Pressable<{ highlight?: boolean }>`
  background-color: ${({ theme, highlight }) =>
    highlight ? theme.colors.secondaryBackground : theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  border-radius: 15px;
  height: auto;
  ${IS_BROWSER && 'cursor: auto'};
  padding: ${({ theme }) =>
    theme.device === 'small' ? '16px' : '32px 24px 24px 24px'};
`

export const Head = styled.View`
`

export const BodyContainer = styled.View`
  flex-direction: ${({ theme }) =>
    theme.device === 'large' ? 'row' : 'column'};
  margin-top: 52px;
`

export const Content = styled.View<DashboardCardOptions & { hidden: boolean }>`
  flex-shrink: 0;
  margin-bottom: ${({ hidden }) => (hidden ? 0 : 32)}px;
`

export const MainContent = styled(Content)`
  padding-horizontal: ${({ removeHorizontalPadding }) =>
    removeHorizontalPadding ? '0px' : '5%'};
`

export const LeftContent = styled(Content)`
 margin-left: ${({ removeHorizontalPadding }) =>
   removeHorizontalPadding ? '0px' : '5%'};
 margin-right: ${({ removeHorizontalPadding, hasRightSpace }) =>
   removeHorizontalPadding && hasRightSpace
     ? '0%'
     : hasRightSpace
     ? '5%'
     : '35%'};
 margin-bottom: ${({ hidden }) => (hidden ? 0 : 60)}px;
 `

export const RightContent = styled(Content)`
  margin-right: 18%;
`

export const LeftColumn = styled.View<{ minHeight: number }>`
  flex-direction: column;
  flex: 1;
  min-height: ${({ minHeight }) =>
    minHeight > 0 ? minHeight + 'px' : IS_BROWSER ? 'unset' : 'undefined'};
`

export const RightColumn = styled.View`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 32%;
  flex-direction: column;
`
