import { NavigationModalProps } from './interface'
import React, { useEffect } from 'react'
import {
  ModalClose,
  ModalContainer,
  ModalWrapper,
  IconContainer,
  Container,
} from './NavigationModal.style'
import { IntercomWrapper as Intercom } from '@core/Wrapper/IntercomWrapper'
import theme from '@ui/UIProvider/defaultTheme'
import { FontIcon } from '@moodwork-ui'
import { useNavigation } from '@react-navigation/native'

export default function NavigationModal({
  children,
  backgroundColor,
  hasBackgroundOpacity = true,
  isClosable = true,
  big = false,
  small = false,
  onClose,
}: NavigationModalProps) {
  const navigation = useNavigation()

  useEffect(() => {
    Intercom.updateUser({
      hide_default_launcher: true,
      hideDefaultLauncher: true,
    })
  }, [])

  const handleClose = () => {
    if (!isClosable) {
      return
    }
    if (onClose) {
      onClose()
    } else {
      navigation.goBack()
    }

    Intercom.updateUser({
      hide_default_launcher: false,
      hideDefaultLauncher: false,
    })
  }

  return (
    <Container backgroundColor={backgroundColor}>
      <ModalWrapper
        onPress={handleClose}
        hasBackgroundOpacity={hasBackgroundOpacity}>
        <ModalContainer
          big={big}
          small={small}
          onPress={(e: any) => e.preventDefault()}>
          {isClosable && (
            <ModalClose onPress={handleClose}>
              <IconContainer>
                <FontIcon name='cancel' color={theme.colors.black} size={16} />
              </IconContainer>
            </ModalClose>
          )}
          {children}
        </ModalContainer>
      </ModalWrapper>
    </Container>
  )
}
