import styled from 'styled-components/native'

export const Container = styled.View<{color: string}>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  padding-horizontal: 16px;
  padding-top: 2px;
  padding-bottom: 16px;
`

export const Content = styled.View`
  margin-right: 16px
`

export const CloseButton = styled.Pressable`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`
