import React from 'react'
import { ResourceArrayProps } from "./interface"
import { Container } from './index.styles'
import { useDevice } from '@ui/utils/device'
import { ResourceArrayBox } from '@ui/Moodwork-UI/molecules/ResourceArrayBox'

export const ResourceArray = ({
  data,
  setFeedback,
  selectedResponse
}: ResourceArrayProps) => {
  const device = useDevice();
  
  return (
    data && data.length > 0 ?
      <Container>
        { data.sort((a, b) => a.priority - b.priority).map((e, i) => 
          <ResourceArrayBox
            large={device === 'small' ? true : ((data.length % 2 != 0) && (i+1 == data.length)) ? true : false}
            key={'resourceArrayBox-'+i+'-'+e.id}
            text={e.title}
            html={e.html}
            id={e.id}
            style={{ flexBasis: device === 'small' ? '100%' : '49%', flexGrow: 1, margin: 1 }}
            setFeedback={setFeedback}
            selectedResponse={selectedResponse}
          />
        )}
      </Container>
    : <></>
  )
}
