import {
  WELLBEING_ASSESSMENT_KEY,
  BURNOUT_ASSESSMENT_KEY,
} from '@core/types/assessment'
import { Line } from '@ui/Moodwork-UI/atoms'
import { Arrow } from '@ui/Moodwork-UI/molecules/AssessmentQuestion/index.styles'
import WellbeingSlider from '@ui/Moodwork-UI/molecules/WellbeingSlider'
import { useTheme } from '@ui/utils'
import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import {
  BurnoutSliderContainer,
  BurnoutQuestion,
  Container,
  Question,
  QuestionContainer,
  SliderContainer,
} from './Assessment.styles'
import { AssessmentTypes } from './interface'
import { View } from 'react-native'

const WellbeingAssessmentSkeleton = () => {
  const theme = useTheme()
  return (
    <>
      {Array.from(Array(6)).map((x, i) => {
        return (
          <View key={`wellbeing-assessment-skeleton-question-${i}`}>
            {i != 0 && <Line height={1} color={'lightSecondaryGrey'}></Line>}
            <QuestionContainer>
              <Question>
                <ContentLoader
                  backgroundColor={theme.colors.lightSecondaryGrey}
                  height='120'
                  width='100%'>
                  <Rect x='0' y='0' rx='3' ry='3' width='100%' height='120' />
                </ContentLoader>
                {theme.device === 'large' && (
                  <Arrow
                    isRight={false}
                    style={{
                      right: 16,
                      borderLeftColor: theme.colors.lightSecondaryGrey,
                    }}
                  />
                )}
              </Question>
              <SliderContainer>
                <WellbeingSlider
                  minValue={-5}
                  maxValue={5}
                  setValue={() => {}}
                  isDisable
                  value={null}
                  style={{
                    height: theme.device === 'large' ? undefined : 110,
                  }}
                />
              </SliderContainer>
              <Question isRight>
                <ContentLoader
                  backgroundColor={theme.colors.lightSecondaryGrey}
                  height='120'
                  width='100%'>
                  <Rect x='0' y='0' rx='3' ry='3' width='100%' height='120' />
                </ContentLoader>
                {theme.device === 'large' && (
                  <Arrow
                    isRight={true}
                    style={{
                      left: 16,
                      borderRightColor: theme.colors.lightSecondaryGrey,
                    }}
                  />
                )}
              </Question>
            </QuestionContainer>
          </View>
        )
      })}
    </>
  )
}

const BurnoutAssessmentSkeleton = () => {
  const theme = useTheme()

  return (
    <>
      {Array.from(Array(6)).map((x, i) => {
        return (
          <>
            {i != 0 && <Line height={1} color={'lightSecondaryGrey'}></Line>}
            <QuestionContainer>
              <BurnoutQuestion>
                <ContentLoader
                  backgroundColor={theme.colors.lightSecondaryGrey}
                  height='120'
                  width='100%'>
                  <Rect x='0' y='0' rx='3' ry='3' width='100%' height='120' />
                </ContentLoader>
                {theme.device === 'large' && (
                  <Arrow
                    isRight={false}
                    style={{
                      right: 16,
                      borderLeftColor: theme.colors.lightSecondaryGrey,
                    }}
                  />
                )}
              </BurnoutQuestion>
              <BurnoutSliderContainer>
                <WellbeingSlider
                  minValue={0}
                  maxValue={5}
                  setValue={() => {}}
                  value={null}
                  isDisable
                  type='burnout'
                  style={{
                    height: theme.device === 'large' ? undefined : 110,
                  }}
                />
              </BurnoutSliderContainer>
            </QuestionContainer>
          </>
        )
      })}
    </>
  )
}

export const AssessmentSkeleton = ({
  assessmentKey,
}: {
  assessmentKey: AssessmentTypes
}) => {
  const theme = useTheme()

  const renderSkeleton = () => {
    switch (assessmentKey) {
      case WELLBEING_ASSESSMENT_KEY:
        return <WellbeingAssessmentSkeleton />
      case BURNOUT_ASSESSMENT_KEY:
        return <BurnoutAssessmentSkeleton />
      default:
        return <WellbeingAssessmentSkeleton />
    }
  }

  return (
    <Container>
      {theme.device === 'large' ? (
        <>
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            height='30'
            width='450'
            style={{
              marginBottom: 24,
            }}>
            <Rect x='0' y='0' rx='3' ry='3' width='450' height='30' />
          </ContentLoader>
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            height='50'
            width='800'
            style={{
              marginBottom: 48,
            }}>
            <Rect x='0' y='0' rx='3' ry='3' width='800' height='50' />
          </ContentLoader>
        </>
      ) : (
        <>
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            height='24'
            width='220'
            style={{
              marginBottom: 8,
            }}>
            <Rect x='0' y='0' rx='3' ry='3' width='220' height='24' />
          </ContentLoader>
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            height='120'
            width='100%'
            style={{
              marginBottom: 32,
            }}>
            <Rect x='0' y='0' rx='3' ry='3' width='100%' height='120' />
          </ContentLoader>
        </>
      )}
      {renderSkeleton()}
    </Container>
  )
}
