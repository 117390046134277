import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ResourceProps } from './interface'
import { DiaryBlock, Resource as ResourceType } from '@core/types/diary'
import {
  LinkWithIcon,
  SectionTitle,
  ResourceBlock,
  PrefixedContent,
  HTMLView,
} from '@moodwork-ui'
import {
  Page,
  BackContainer,
  MenuLeft,
  MenuContent,
  ResourceContent,
  MiddleContent,
  Bookmark,
  FeedbackContainer,
  Feedback,
  HintText,
} from './Resource.styles'
import { OnDevice } from '@ui/OnDevice'
import { goBack } from '@core/lib/navigation'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { View, FlatList, useWindowDimensions, Pressable } from 'react-native'
import { Mark } from '@ui/Moodwork-UI/organisms/Mark'
import ResourceSubHeader from '@ui/Moodwork-UI/organisms/Resource/ResourceSubHeader'
import { HeaderTopContext, Provider } from '@ui/Page/Page'
import { ResourceRecommendationCard } from '@ui/Moodwork-UI/organisms/Resource/ResourceRecommandationCard'
import {
  MenuResourcesLoader,
  ResourcesLoader,
} from '@ui/Moodwork-UI/templates/Resource/Resources.loading'
import { ResourcePDF } from '@ui/Moodwork-UI/organisms/Resource/ResourcePDF'
import { useTheme } from '@ui/utils'
import { useTranslation } from 'react-i18next'
import { useToggle } from '@core/lib/hooks'
import Expandable from '@ui/Moodwork-UI/atoms/Expandable'
import { useFavorites, useSendDiaryMark } from '@services/resources'
import { ResourceCollectionNext } from '@ui/Moodwork-UI/organisms/Resource/ResourceCollectionNext'
import { trackEvent } from '@core/lib/analytics'
import shareIcon from '../../../../assets/images/ic_share.png'
import { ShareImage } from '@ui/Moodwork-UI/molecules/Resource/FooterInformations/index.styles'
import { BookmarkButton } from '@ui/Moodwork-UI/molecules/pressables/BookmarkButton'
import { MODAL_SHARE_RESOURCE } from '@core/constants'
import { Heading } from '@ui/Moodwork-UI/organisms/Resource/ResourceBlock/ResourceBlock.styles'
import {
  ResourceResponse,
  typeColor,
} from '@ui/Moodwork-UI/organisms/Resource/ResourceResponse'
import { ResourceResponseStatus } from '@ui/Moodwork-UI/organisms/Resource/ResourceResponse/interface'
import { ResourceArray } from '@ui/Moodwork-UI/organisms/Resource/ResourceArray'

const useContentWidth = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const contentWidth = useMemo(() => {
    switch (theme.device) {
      case 'small':
        return width - 32 //
      case 'medium':
        return width - 60 // padding
      case 'large':
        return width / 2 - 56 * 2 // 50% width - padding
    }
  }, [width, theme.device])
  return contentWidth
}

const Item = ({
  resource,
  collection,
  resourceUuid,
  item: {
    id,
    title,
    html,
    icon,
    background_type,
    prefix,
    type,
    file,
    video,
    expandable,
    status,
    last_item,
    children,
  },
  handleFeedback,
  selectedResponse,
}: {
  resource: ResourceType
  resourceUuid: string
  item: DiaryBlock
  collection: string
  handleFeedback: (
    feedback: string,
    status: ResourceResponseStatus,
    position: number,
    selectedId: number
  ) => void
  selectedResponse: number
}) => {
  const renderBlock = () => {
    const contentWidth = useContentWidth()
    switch (type) {
      case 'block':
        return (
          // Todo: remove dependency between resourceblock and expandable
          <ResourceBlock
            title={!expandable ? title : null}
            bottomMargin={!expandable}
            smallText={expandable}
            imageUrl={icon?.url}
            imagePosition={icon?.float}
            imageDimension={icon?.dimension}
            background={background_type}
            content={html}
            prefix={prefix}
            contentWidth={contentWidth}
            file={file}
            video={video}
            trackableParams={{
              collection_uuid: collection,
              uuid: resource.uuid,
              title: resource.title,
              theme: resource.theme?.title,
              tag: resource.sticker?.name,
            }}
          />
        )
      case 'blockpdf':
        return (
          <ResourcePDF
            title={title}
            background={background_type}
            content={html}
            file={file}
            trackableParams={{
              collection_uuid: collection,
              uuid: resource.uuid,
              title: resource.title,
              theme: resource.theme?.title,
              tag: resource.sticker?.name,
              file: file?.url
                ? decodeURI(
                    file?.url.substring(
                      file?.url.lastIndexOf('/') + 1,
                      file?.url.length
                    )
                  )
                : '',
            }}
          />
        )
      case 'blockmark':
        return (
          <Mark
            uuid={resourceUuid}
            trackableParams={{
              collection_uuid: collection,
              uuid: resource.uuid,
              title: resource.title,
              theme: resource.theme?.title,
              tag: resource.sticker?.name,
            }}
          />
        )
      case 'blocksuggestions':
        const suggestions = [
          <ResourceRecommendationCard
            resourceUuid={resourceUuid}
            resourceTheme={resource.theme?.title}
            resourceTitle={resource.title}
            collectionId={collection}
            trackableParams={{
              collection_uuid: collection,
              uuid: resource.uuid,
              theme: resource.theme?.title,
              title: resource.title,
              tag: resource.sticker?.name,
            }}
          />,
        ]

        if (!!collection && collection !== 'undefined') {
          // fix diary_groups/undefined call
          suggestions.unshift(
            <ResourceCollectionNext
              collection={collection}
              resourceUuid={resourceUuid}
              resourceTheme={resource.theme?.title}
              resourceTitle={resource.title}
              resourceTag={resource.sticker?.name}
            />
          )
        }
        return suggestions

      case 'blockquestion':
        return (
          <Heading>
            <PrefixedContent alignPosition='center' prefix='question'>
              <SectionTitle>{title}</SectionTitle>
            </PrefixedContent>
            {html && <HintText>{html}</HintText>}
          </Heading>
        )

      case 'blockresponse':
        return (
          <ResourceResponse
            id={id}
            title={title}
            html={html}
            status={status}
            setFeedback={handleFeedback}
            lastItem={last_item}
            selectedResponse={selectedResponse}
          />
        )

      case 'blockcontainer':
        return (
          <ResourceArray
            data={children}
            setFeedback={handleFeedback}
            selectedResponse={selectedResponse}
          />
        )

      default:
        return null
    }
  }

  const Wrapper = type === 'blocksuggestions' ? View : MiddleContent
  return (
    <Wrapper>
      {expandable ? (
        <Expandable title={title}>{renderBlock()}</Expandable>
      ) : (
        renderBlock()
      )}
    </Wrapper>
  )
}

export const Resource = ({
  resource,
  collection,
  isLoading,
}: ResourceProps) => {
  const navigation = useNavigation()
  const [selectedResponse, setSelectedResponse] = useState<number>(0)
  const [feedback, setFeedback] = useState<string | null>(null)
  const [status, setStatus] = useState<ResourceResponseStatus>('neutral')
  const [positionY, setPositionY] = useState<number | null>(null)

  const handleFeedback = (
    content: string,
    type: ResourceResponseStatus,
    position: number,
    selectedId: number
  ) => {
    setFeedback(content)
    setStatus(type)
    setPositionY(position)
    setSelectedResponse(selectedId)
  }

  const renderItem = useCallback(
    ({ item }) => (
      <Item
        collection={collection}
        resource={resource}
        resourceUuid={resource.uuid}
        item={item}
        handleFeedback={handleFeedback}
        selectedResponse={selectedResponse}
      />
    ),
    [collection, resource, selectedResponse]
  )

  const headerIsTop = useContext(HeaderTopContext)
  const theme = useTheme()
  const { t } = useTranslation(['resources'])
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const [subHeaderHeight, setSubHeaderHeight] = useState()

  const handleBackButton = useCallback(() => {
    goBack(navigation)
  }, [navigation])
  const { mutate } = useSendDiaryMark(resource?.uuid)

  const { mutate: sendFavorite, data: favorites } = useFavorites()

  const onFavoritePress = useCallback(
    (addFavorite: boolean) => {
      trackEvent(
        addFavorite
          ? 'clicked_resource_add_to_favorites'
          : 'clicked_resource_remove_from_favorites',
        {
          uuid: resource?.uuid,
          title: resource?.title,
          tag: resource?.sticker?.name,
        }
      )
      sendFavorite({
        type: 'diaries',
        resourceUuid: resource?.uuid,
        addFavorite,
      })
    },
    [resource?.uuid]
  )

  const onSharePress = useCallback(() => {
    trackEvent('clicked_resource_share', {
      uuid: resource?.uuid,
      title: resource?.title,
      tag: resource?.sticker?.key,
    }).then(() => {
      navigation.navigate(MODAL_SHARE_RESOURCE, {
        resourceType: 'diaries',
        resourceUuid: resource?.uuid,
        resourceStickerKey: resource?.sticker?.key,
        resourceTitle: resource?.title,
      })
    })
  }, [resource?.uuid])

  const isFavorite =
    favorites != null && favorites[resource?.uuid] !== undefined
      ? favorites[resource?.uuid]
      : resource?.favorite

  useFocusEffect(
    useCallback(() => {
      if (resource) {
        const sendMark = setTimeout(() => {
          mutate({})
        }, 60000)
        return () => clearTimeout(sendMark)
      }
    }, [resource])
  )

  useEffect(() => {
    if (resource) {
      trackEvent('viewed_resource_fav_status', {
        collection_uuid: collection,
        uuid: resource.uuid,
        title: resource.title,
        tag: resource.sticker?.name,
        theme: resource.theme?.title,
        is_favorited: isFavorite,
      })
    }
  }, [resource])

  return (
    <Page>
      <OnDevice size='large'>
        <MenuLeft>
          <MenuContent headerIsTop={headerIsTop}>
            {isLoading ? (
              <MenuResourcesLoader />
            ) : (
              <>
                <BackContainer hover={hovered}>
                  <LinkWithIcon
                    onHoverIn={onHoverIn}
                    onHoverOut={onHoverOut}
                    IconPosition='left'
                    iconName='arrow-left'
                    iconSize={16}
                    iconColor={hovered ? 'white' : 'black'}
                    textSize='XXS'
                    textColor={hovered ? 'white' : 'black'}
                    label={t('menu.back')}
                    trackLabel='clicked_resource_go_back'
                    trackProperties={{
                      collection_uuid: collection,
                      uuid: resource.uuid,
                      title: resource.title,
                      theme: resource.theme?.title,
                    }}
                    paddingSize='5px 14px 5px 0px'
                    onPress={handleBackButton}
                  />
                </BackContainer>
                <SectionTitle weight='semiBold'>{resource.title}</SectionTitle>
                <Bookmark>
                  <BookmarkButton
                    favorite={isFavorite}
                    onFavorite={onFavoritePress}
                  />
                  <Pressable onPress={onSharePress}>
                    <ShareImage source={shareIcon} />
                  </Pressable>
                </Bookmark>
              </>
            )}
          </MenuContent>
        </MenuLeft>
      </OnDevice>
      <ResourceContent hasAudio={resource?.audio?.url} isLoading={isLoading}>
        {isLoading ? (
          <ResourcesLoader />
        ) : (
          <>
            <Provider>
              <ResourceSubHeader
                onLayout={(e) => {
                  setSubHeaderHeight(e.nativeEvent.layout.height)
                }}
                resource={resource}
                favorite={isFavorite}
                onFavorite={onFavoritePress}
                onShare={onSharePress}
                feedback={feedback}
                setFeedback={handleFeedback}
                status={status}
              />
            </Provider>
            <FlatList
              contentContainerStyle={{
                marginTop:
                  subHeaderHeight && theme.device != 'medium'
                    ? theme.device === 'small'
                      ? subHeaderHeight - 64
                      : subHeaderHeight - 128
                    : 0,
              }}
              data={resource.body}
              renderItem={renderItem}
              ListFooterComponent={<></>} // to activate ListFooterComponentStyle
              ListFooterComponentStyle={{
                marginBottom: theme.spacings.FOOTER,
              }}
            />
          </>
        )}
      </ResourceContent>
      {theme.device === 'large' && feedback && status && positionY && (
        <FeedbackContainer top={positionY}>
          <Feedback backgroundColor={typeColor(status)}>
            <HTMLView
              source={
                status === 'neutral'
                  ? feedback
                  : '<p><strong>' +
                    t(`resources.responses.${status}Title`) +
                    '</strong></p>' +
                    feedback
              }
            />
          </Feedback>
        </FeedbackContainer>
      )}
    </Page>
  )
}
