import { useDeleteAccount, useDownloadData } from '@core/services/viewer'
import { Button, SubTitle, Title } from '@ui/Moodwork-UI'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { TextInput } from '@ui/TextInput'
import React, { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Container } from './index.styles'
import { IS_NATIVE } from '@ui/utils'
import { Keyboard } from 'react-native'
import AlertHandler, { AlertContext } from '@core/components/Alerthandler'
import { useLinkTo } from '@react-navigation/native'
import { HOST } from '@core/constants'

export default function DeleteModal() {
  return (
    <AlertHandler>
      <Modal />
    </AlertHandler>
  )
}

const Modal = () => {
  const { mutateAsync, data, error } = useDeleteAccount()
  const [password, setPassword] = useState('')
  const { setMessage, setType } = useContext(AlertContext)
  const linkTo = useLinkTo()

  useEffect(() => {
    if (data) {
      setType('success')
      setMessage(data.data.message)
    }
    if (error) {
      setType('warning')
      setMessage(error.response.data.message)
    }
  }, [data, error])

  return (
    <View style={{ flex: 1 }}>
      <NavigationModal small>
        <Container onPress={() => IS_NATIVE && Keyboard.dismiss()}>
          <SubTitle>Supprimer votre compte</SubTitle>
          <TextInput
            onChangeText={setPassword}
            passwordIconTopPosition={37}
            type='password'
            label='Afin de supprimer votre compte, veuillez entrer votre mot de passe :'
          />
          <Button
            label='Supprimer'
            onPress={() => {
              if (password !== '') {
                mutateAsync({ password: password })
              }
            }}
          />
        </Container>
      </NavigationModal>
    </View>
  )
}
