import { Line, Paragraph, Text, Title } from '@ui/Moodwork-UI/atoms'
import React, { useEffect, useState } from 'react'
import {
  SwitchButton,
  SwitchContainer,
  SwitchTextContainer,
} from './index.styles'
import { useTheme } from '@ui/utils'
import { useUpdateUser, useUserInfo } from '@core/services/viewer'

export default function EmailMenu() {
  const { data } = useUserInfo()
  const [newsletterValue, setNewsletterValue] = useState(
    data?.mail_communication
  )
  const theme = useTheme()

  const { mutateAsync } = useUpdateUser()

  return (
    <>
      <Title
        style={{ paddingHorizontal: 32 }}
        weight='regular'
        marginSize='-8px 0 0 0'>
        Emails
      </Title>
      <Text style={{ paddingHorizontal: 32, paddingBottom: 16 }}>
        Recevoir des emails informatifs
      </Text>
      <Line height={2} color='separatorGrey' />
      <SwitchContainer>
        <SwitchTextContainer>
          <Text>Newsletter</Text>
          <Text size='XS'>Recevoir des mails infortmatifs sur Moodwork</Text>
        </SwitchTextContainer>
        <SwitchButton
          activeThumbColor={theme.colors.secondary}
          trackColor={{
            true: theme.colors.secondaryLight,
            false: theme.colors.lightSecondaryGrey,
          }}
          value={newsletterValue}
          onValueChange={(value) => {
            mutateAsync({ subToNewsletter: value })
            setNewsletterValue(value)
          }}
        />
      </SwitchContainer>
    </>
  )
}
