import styled, { css } from 'styled-components/native'
import { Text } from '@ui/Moodwork-UI/atoms'
import { IS_NATIVE } from '@ui/utils'

const wordBreak = css`
  word-break: break-word;
`

export const Container = styled.View<{ isRight: boolean; tooltip: boolean }>`
  height: 100%;
  background-color: #EBF0F9;
  border-radius: 4px;
  flex-direction: ${({ isRight }) => (isRight ? 'row-reverse' : 'row')};
  padding: 32px 16px;
  width: 256px;
  align-items: center;
  justify-content: ${({ isRight, tooltip }) =>
    isRight ? (tooltip ? 'space-between' : 'flex-end') : 'flex-start'};
`

export const Image = styled.Image<{ isRight: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: ${({ isRight }) => (isRight ? 0 : 16)}px;
  margin-left: ${({ isRight }) => (isRight ? 16 : 0)}px;
`

export const Arrow = styled.View<{ isRight: boolean }>`
  width: 0;
  height: 0;
  border-right-width: ${({ isRight }) => (isRight ? 16 : 0)}px;;
  border-bottom-width: 16px;
  border-top-width: 16px;
  border-left-width: ${({ isRight }) => (isRight ? 0 : 16)}px;
  border-color: transparent;
  border-left-color: ${({ isRight }) => (isRight ? 'transparent' : '#EBF0F9')};
  border-right-color: ${({ isRight }) => (isRight ? '#EBF0F9' : 'transparent')};
  position: absolute;
  right: ${({ isRight }) => (isRight ? 'undefined' : '-16px')};
  left: ${({ isRight }) => (isRight ? '-16px' : 'undefined')};
`

export const QuestionText = styled(Text)`
  flex-wrap: wrap;
  flex-shrink: 1;
  ${!IS_NATIVE && wordBreak}
`
