import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Container,
  ResponseContainer,
} from './ResourceResponse.styles'
import { ResourceResponseStatus, ResourceResponseProps } from './interface'
import { BigParagraph } from '@ui/Moodwork-UI/atoms/Text'
import { useToggle } from '@core/lib/hooks'
import { useTheme } from '@ui/utils'
import { OnScrollContext } from '@ui/Page/Page'

export const typeColor = (type: ResourceResponseStatus) => {
  switch (type) {
    case 'correct':
      return 'lightGreen'
    case 'wrong':
      return 'red'
    case 'close':
      return 'primary'
    case 'neutral':
      return 'secondary'
  }
}

export const ResourceResponse = ({
  id,
  title,
  html,
  status = 'neutral',
  setFeedback,
  lastItem,
  selectedResponse,
}: ResourceResponseProps) => {
  const [selected, setSelected] = useState<boolean>(false)
  const [hover, onHoverIn, onHoverOut] = useToggle()
  const [positionY, setPositionY] = useState(0)
  const theme = useTheme()
  const responseRef = useRef(null)
  const { onScroll } = useContext(OnScrollContext)

  const handlePress = () => {
    if (!selected) {
      setFeedback(html, status, positionY, id)
    } else {
      id === selectedResponse && setFeedback(null, null, null, 0)
    }
    setSelected(!selected)
  }

  const handlePosition = () => {
    responseRef.current &&
      responseRef.current.measure((width, height, px, py, fx, fy) => {
        setPositionY(fy - 49 + (onScroll?.nativeEvent?.contentOffset?.y ?? 0))
      })
  }

  useEffect(() => {
    handlePosition()
  }, [onScroll, responseRef, selectedResponse])

  return (
    <Container lastItem={lastItem}>
      <ResponseContainer
        ref={responseRef}
        onLayout={handlePosition}
        selected={selected}
        hover={hover}
        onPress={handlePress}
        onMouseEnter={onHoverIn}
        onMouseLeave={onHoverOut}
        typeColor={typeColor(status)}>
        <BigParagraph
          style={{
            color:
              selected && status === 'neutral'
                ? theme.colors.white
                : theme.colors.black,
          }}>
          {title}
        </BigParagraph>
      </ResponseContainer>
    </Container>
  )
}
