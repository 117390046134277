import React, { useContext, useEffect, useState } from 'react'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { Container, Content, FormContainer, Input } from './index.styles'
import { BrandedContainer, Button } from '@ui/Moodwork-UI/molecules'
import { Text, Title } from '@ui/Moodwork-UI/atoms'
import { useTranslation } from 'react-i18next'
import {
  PASSWORD_REGEX,
  SCREEN_HOME,
  SCREEN_PROFILING,
  SCREEN_REGISTRATION,
} from '@core/constants'
import { AlertContext } from '@core/components/Alerthandler'
import { usePassword, useUserInfo } from '@core/services/viewer'
import { IntercomWrapper } from '@core/Wrapper/IntercomWrapper'

export default function PasswordForm() {
  const { t, i18n } = useTranslation('sign')
  const navigation = useNavigation()
  IntercomWrapper.logout()
  const route = useRoute()
  const params = route.params
  const { setMessage, setType } = useContext(AlertContext)
  const { mutate, data } = usePassword(
    params?.reset_password_token,
    params?.locale ?? i18n.language
  )
  const { refetch } = useUserInfo()

  const [password, setPassword] = useState('')
  const [isPasswordGood, setPasswordGood] = useState<boolean | undefined>()
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const onPasswordChange = (value: string) => {
    setPassword(value)
    setPasswordGood(
      !!value.match(PASSWORD_REGEX[params?.password_policy_level - 1])
    )
  }

  const submit = () => {
    setType('warning')
    if (!isPasswordGood) {
      setMessage("Le mot de passe n'a pas la bonne complexité")
      return
    }
    if (passwordConfirmation !== password) {
      setMessage('Mot de passe et confirmation non identique')
      return
    }

    mutate({ password: password })
  }

  useEffect(() => {
    if (params?.locale) {
      i18n.changeLanguage(params.locale)
    }
  }, [])

  useEffect(() => {
    if (data) {
      refetch().then(({ data }) => {
        if (data?.questions_to_answer_type === 'profile') {
          navigation.dispatch(StackActions.replace(SCREEN_PROFILING))
        } else if (data?.questions_to_answer_type === 'registration') {
          navigation.dispatch(StackActions.replace(SCREEN_REGISTRATION))
        } else {
          navigation.dispatch(StackActions.replace(SCREEN_HOME))
        }
      })
    }
  }, [data])

  return (
    <Container>
      <BrandedContainer>
        <Content>
          <Title marginSize='32px 0 0 0'>{t('up.password.title')}</Title>
          <FormContainer>
            <Input
              secureTextEntry
              onChange={onPasswordChange}
              value={password}
              placeholder={t('up.form.password')}
            />
            <Input
              secureTextEntry
              onChange={setPasswordConfirmation}
              value={passwordConfirmation}
              placeholder={t('up.form.confirm-password')}
            />
            <Text
              color={
                isPasswordGood === undefined
                  ? 'grey'
                  : isPasswordGood
                  ? 'green'
                  : 'red'
              }
              style={{ marginTop: -12 }}>
              {decodeURI(params?.password_policy_hint).replaceAll('+', ' ')}
            </Text>
          </FormContainer>
          <Button
            trackLabel='clicked_signup_register'
            label={t('up.form.button')}
            onPress={submit}
          />
        </Content>
      </BrandedContainer>
    </Container>
  )
}
