import styled, { css } from 'styled-components/native'
import { Colors } from '@ui/UIProvider/UIProvider.types'
import { IS_BROWSER } from '@utils'
import { Pressable } from 'react-native'

const web =
  IS_BROWSER &&
  `
    cursor: pointer
  `

export const Container = styled(Pressable)<{
  borderRadiusSize: string
  backgroundColor: keyof Colors
  hovered: boolean
}>`
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};
  border-radius: ${({ borderRadiusSize }) => borderRadiusSize};
  align-items: center;
  border: 2px solid transparent;
  ${({ hovered, theme }) =>
    hovered &&
    css`
     border-color: ${theme.colors.searchBlue};
  `}
`
export const IconContainer = styled(Pressable)`
  flex: 1;
  max-width: 50px;
`

export const InputContainer = styled.View`
  flex: 1;
  height: 100%;
  width: 100%;
`

export const InputSearchText = styled.TextInput`
  outline-style: none;
  width: 100%;
  height: 100%;
  font-size: ${({ theme }) => theme.textSizes.S};
`
