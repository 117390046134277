import { useDownloadData } from '@core/services/viewer'
import { Button, SubTitle } from '@ui/Moodwork-UI'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { TextInput } from '@ui/TextInput'
import React, { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Container } from './index.styles'
import { IS_NATIVE } from '@ui/utils'
import { Keyboard } from 'react-native'
import AlertHandler, { AlertContext } from '@core/components/Alerthandler'

export default function DownloadDataModal() {
  return (
    <AlertHandler>
      <Modal />
    </AlertHandler>
  )
}

const Modal = () => {
  const { mutateAsync, data, error } = useDownloadData()
  const [password, setPassword] = useState('')
  const { setMessage, setType } = useContext(AlertContext)

  useEffect(() => {
    if (data) {
      setType('success')
      setMessage(data.data.message)
      return
    }
    if (error) {
      setType('warning')
      setMessage(error.response.data.message)
      return
    }
  }, [data, error])
  return (
    <View style={{ flex: 1 }}>
      <NavigationModal small>
        <Container onPress={() => IS_NATIVE && Keyboard.dismiss()}>
          <SubTitle>Télécharger vos informations</SubTitle>
          <TextInput
            style={{ width: '100%' }}
            type='password'
            passwordIconTopPosition={37}
            onChangeText={setPassword}
            label='Entrez votre mot de passe :'
          />
          <Button
            label='Télécharger'
            onPress={() => {
              if (password !== '') {
                mutateAsync({ password: password })
              }
            }}
          />
        </Container>
      </NavigationModal>
    </View>
  )
}
