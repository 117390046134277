import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Line, Text, Title } from '@ui/Moodwork-UI/atoms'
import AssessmentQuestionCard from '../AssessmentQuestionCard'
import { AssessmentProps } from './interface'
import { SeparatorContainer } from './index.styles'
import { Button } from '@ui/Moodwork-UI/molecules'
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { SCREEN_HOME, SCREEN_TEST_RESULT } from '@core/constants'
import { useSendAnswer } from '@core/services/assessments'
import { Pressable, FlatList, useWindowDimensions } from 'react-native'
import AssessmentHeader from '@ui/Moodwork-UI/molecules/AssessmentHeader/AssessmentHeader'
import { IS_NATIVE, useTheme } from '@ui/utils'
import { debounce, first } from 'lodash'
import { trackEvent } from '@core/lib/analytics'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AssessmentsNavigation } from '@core/lib/types'
import { AssessmentContainer } from './Assessment.styles'
import {
  BURNOUT_ASSESSMENT_KEY,
  WELLBEING_ASSESSMENT_KEY,
} from '@core/types/assessment'

export const Assessment = ({ data }: AssessmentProps) => {
  const listRef = useRef<FlatList>()
  const params = useRoute<AssessmentsNavigation>().params
  const navigation = useNavigation()
  const { t } = useTranslation(['assessment'])
  const theme = useTheme()
  const { mutateAsync, isLoading } = useSendAnswer(data?.user_test?.uuid)
  const [currentIndex, setCurrentIndex] = useState(
    data?.user_test?.answers?.length ?? 0
  )
  const [isFinish, setIsFinish] = useState(false)
  const [nbReversed, setNbReversed] = useState(0)
  const { height } = useWindowDimensions()

  const reversed = () => {
    if ((params?.key ?? WELLBEING_ASSESSMENT_KEY) === BURNOUT_ASSESSMENT_KEY) {
      return false
    }
    if (nbReversed === 3) {
      setNbReversed(0)
      return true
    }
    if (nbReversed === -3) {
      setNbReversed(0)
      return false
    }
    if (Math.random() < 0.5) {
      setNbReversed(nbReversed > 0 ? nbReversed + 1 : 0)
      return false
    } else {
      setNbReversed(nbReversed < 0 ? nbReversed - 1 : 0)
      return true
    }
  }

  const title = useMemo(() => data?.test?.title, [data])
  const subtitle = useMemo(() => data?.test?.subtitle, [data])
  const response = useMemo(
    () =>
      (data?.user_test?.answers ?? []).reduce((acc, curr) => {
        return { ...acc, [curr.uuid]: curr.score }
      }, {}),
    [data]
  )
  const questions = useMemo(
    () =>
      data?.investigation?.inspections
        .map((question) => ({
          ...question,
          reverse: reversed(),
          response: data.investigation.responses[question.response.uuid],
          score: response[question.uuid],
        }))
        .sort((a, b) => (a.score ? -1 : 0)),
    [data]
  )

  useFocusEffect(
    useCallback(() => {
      if (
        data &&
        (params?.key ?? WELLBEING_ASSESSMENT_KEY) === 'wellbeing_assessment'
      ) {
        trackEvent('viewed_wellbeing_assessment', {
          user_test_id: data.user_test.uuid,
          resumed_from_save: data.user_test.status === 'pending',
          test_start_date:
            data.user_test.status === 'pending'
              ? first(data.user_test?.answers)?.created_at ??
                data.user_test.created_at
              : moment(data.user_test.created_at)
                  .add(2, 'minutes')
                  .isAfter(moment())
              ? data.user_test.created_at
              : moment().toISOString(),
          nb_questions_answered: data.user_test?.answers?.length ?? 0,
        })
      }
    }, [data])
  )

  const onValueChange = (index: number, score: number, questionId: string) => {
    debounce(
      () =>
        mutateAsync([{ score: score, uuid: questionId }]).then((response) => {
          if (response.data.status === 'close') {
            setIsFinish(true)
            return
          }
        }),
      0
    )()
    if (index + 1 === questions.length) {
      return
    }
    listRef.current?.scrollToIndex({
      animated: true,
      index: index + 1,
      viewPosition: 0.5,
    })
    if (index + 1 > currentIndex) {
      setCurrentIndex(index + 1)
    }
  }

  // if 10 click on Title autocomplete form with random positive data
  const autoCompleteForm = () => {
    mutateAsync(
      questions.map((q) => ({
        score: Math.round(Math.random() * 5),
        uuid: q.uuid,
      }))
    ).then((e) => {
      navigation.push(SCREEN_TEST_RESULT, { id: data.user_test.uuid })
    })
  }

  return (
    <AssessmentContainer>
      <AssessmentHeader
        maxCount={questions.length}
        currentCount={currentIndex + 1}
        onPress={() => {
          if (!isLoading && params?.key) {
            trackEvent(
              params?.key === 'further_assessment'
                ? 'clicked_burnout_assessment_save'
                : `clicked_${params?.key}_save`,
              {
                user_test_id: data.user_test.uuid,
                nb_questions_answered: currentIndex,
              }
            ).then(() => {
              navigation.push(SCREEN_HOME)
            })
          }
        }}
      />
      <FlatList
        ref={listRef}
        data={questions}
        legacyImplementation={false}
        style={{ maxHeight: height }}
        contentContainerStyle={{
          paddingTop: 64,
          marginHorizontal: theme.device === 'large' ? 'auto' : 16,
          width: theme.device !== 'large' ? undefined : '100%',
          minWidth: theme.device !== 'large' ? undefined : 992,
          maxWidth: 1350,
        }}
        ListFooterComponentStyle={{ padding: 64 }}
        ListFooterComponent={() => (
          <Button
            style={{
              width: theme.device === 'small' ? '80%' : '25%',
              alignSelf: 'center',
            }}
            label={t('submit')}
            disabled={!isFinish}
            loading={isLoading}
            trackLabel={
              (params?.key ?? WELLBEING_ASSESSMENT_KEY) ===
              WELLBEING_ASSESSMENT_KEY
                ? 'clicked_wellbeing_assessment_submit'
                : 'clicked_burnout_assessment_submit'
            }
            trackProperties={{ user_test_id: data.user_test.uuid }}
            onPress={() => {
              navigation.push(SCREEN_TEST_RESULT, { id: data.user_test.uuid })
            }}
          />
        )}
        ListHeaderComponentStyle={{
          margin: theme.device === 'large' ? 64 : 32,
        }}
        ListHeaderComponent={() => {
          return (
            <Pressable
              style={{ cursor: 'default' }}
              onPress={(e) => e.detail === 10 && autoCompleteForm()}>
              <Title marginSize='0 0 24px 0' alignPosition='center'>
                {title}
              </Title>
              <Text alignPosition='center'>{subtitle}</Text>
            </Pressable>
          )
        }}
        renderItem={({ item, index }) => {
          return (
            <AssessmentQuestionCard
              type={
                (params?.key ?? WELLBEING_ASSESSMENT_KEY) ===
                BURNOUT_ASSESSMENT_KEY
                  ? 'burnout'
                  : 'wellbeing'
              }
              key={item.uuid}
              isLoading={isLoading}
              isDisable={index > currentIndex}
              onValueChange={(score, questionId) =>
                onValueChange(index, score, questionId)
              }
              style={{ marginHorizontal: theme.device === 'large' ? 64 : 0 }}
              question={item}
            />
          )
        }}
        ItemSeparatorComponent={() => (
          <SeparatorContainer>
            <Line height={1} color='separatorGrey' />
          </SeparatorContainer>
        )}
      />
    </AssessmentContainer>
  )
}
