import styled, { css } from 'styled-components/native'

export const Container = styled.Pressable`
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) =>
    theme.device !== 'small' &&
    css`
    padding: 32px;
  `}
`
