import styled from 'styled-components/native'
import { ResourceContentCSS, ResourceMarginBottomContentCSS } from '../ResourceBlock/ResourceCommon.styles'

export const Container = styled.View`
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  flex-basis: auto;
  ${ResourceContentCSS};
  ${ResourceMarginBottomContentCSS};
`
